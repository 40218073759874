<template>
  <div id="Container">
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :selection="{ mode: 'single' }"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cellDblClick="onDblClick">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxColumnChooser :enabled="true" mode="select" />

      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"/>

      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="code"
        caption="건설현장 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[A-Z0-9_]{17}$"
          message="영문 대문자 숫자 _ 로만 사용, 17자로 등록 필요"/>
      </DxColumn>
      
      <DxColumn
        data-field="code2"
        caption="성적서 번호(BIPV)"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^[A-Z0-9_]{17}$"
          message="영문 대문자 숫자 _ 로만 사용, 17자로 등록 필요"/>
      </DxColumn>

      <DxColumn
        data-field="code3"
        caption="성적서 번호(KZIP)"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^[A-Z0-9_]{17}$"
          message="영문 대문자 숫자 _ 로만 사용, 17자로 등록 필요"/>
      </DxColumn>

      <DxColumn
        data-field="code4"
        caption="성적서 번호(KZIP2)"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^[A-Z0-9_]{17}$"
          message="영문 대문자 숫자 _ 로만 사용, 17자로 등록 필요"/>
      </DxColumn>
      
      <DxColumn
        data-field="name"
        caption="건설현장 명"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,30}$"
          message="30자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="address"
        caption="대지위치"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,100}$"
          message="100자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="landNumber"
        caption="지번"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,100}$"
          message="100자까지 등록 가능"/>
      </DxColumn>
      
      <DxColumn
        data-field="customer"
        caption="고객(발주처)"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,40}$"
          message="40자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="customerAddress"
        caption="고객 주소"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,100}$"
          message="100자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="customerTel"
        caption="고객 전화번호"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,20}$"
          message="20자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="receiptDate"
        caption="공사 접수일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="startDate"
        caption="공사 시작일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="endDate"
        caption="공사 종료일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="cqBipv"
        caption="시공량(BIPV)"
        data-type="number">
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$"
          message="1 ~ 9999999999 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="cqKzip"
        caption="시공량(KZIP)"
        data-type="number">
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$"
          message="1 ~ 9999999999 까지 등록 가능"/>  
      </DxColumn>

      <DxColumn
        data-field="cqKzip2"
        caption="시공량(KZIP2)"
        data-type="number">
        <DxPatternRule
          pattern="^[1-9]{1}[0-9]{0,9}$"
          message="1 ~ 9999999999 까지 등록 가능"/>  
      </DxColumn>
          
      <DxColumn
        data-field="complete"
        caption="상태">  
        <DxLookup
          :data-source="statusComplete"
          value-expr="ID"
          display-expr="Name"/>
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="salesUserId"
        caption="영업 담당자">  
        <DxLookup
          :data-source="userList"
          value-expr="id"
          display-expr="userNameId"/>
        <DxRequiredRule/>
      </DxColumn>

      <DxColumn
        data-field="constructionSiteManager"
        caption="현장 소장"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,6}$"
          message="6자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="type"
        caption="내화 구분">  
        <DxLookup
          :data-source="materialType"
          :allow-clearing="true"/>
      </DxColumn>

      <DxColumn
        data-field="supervisionCompany"
        caption="감리회사"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,20}$"
          message="20자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="contractor"
        caption="시공업자"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,10}$"
          message="10자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="checker"
        caption="확인자"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,10}$"
          message="10자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="memo"
        caption="Memo"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,50}$"
          message="50자까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="startDateInspPeriod"
        caption="검사시기 시작일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
      </DxColumn>

      <DxColumn
        data-field="endDateInspPeriod"
        caption="검사시기 종료일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
      </DxColumn>

      <DxColumn
        data-field="startDateInsp"
        caption="검사일자 시작일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
      </DxColumn>

      <DxColumn
        data-field="endDateInsp"
        caption="검사일자 종료일"
        data-type="date"
        :header-filter="{ allowSearch: true }"
        format="yyyy/MM/dd">
      </DxColumn>

      <DxColumn
        data-field="file0001"
        caption="계약서"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>  

      <DxColumn
        data-field="file0002"
        caption="계약 내역서"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/> 

      <DxColumn
        data-field="file0003"
        caption="계약 검토 보고서"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>   

      <DxColumn
        data-field="file0004"
        caption="인수 인계서"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>   

      <DxColumn
        data-field="file0005"
        caption="담당자 연락처"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>   

      <DxColumn
        data-field="file0006"
        caption="지급 보증서"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>   

      <DxColumn
        data-field="file0007"
        caption="도면"
        alignment="center"
        cell-template="fileCellTemplate"
        :formItem="{visible: false}"/>   

      <DxColumn
        data-field="regUserId"
        caption="등록자"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
        <DxFormItem :visible="false"/>
      </DxColumn>

      <DxMasterDetail 
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/erp/EMHJ/selectHistory`"
                  :keyName="code"
                  :columns="historyColumns"/>
        </template>

      <template #fileCellTemplate="{ data }">
          <span v-if="data.column.dataField.includes('file') && data.data[data.column.dataField]" class="dx-icon-download"/>
      </template>
    </DxDataGrid>

    <DxPopup :ref="popupUploadFileRefName"
            :drag-enabled="false"
            :close-on-outside-click="true"
            :show-title="true"
            :width="400"
            :height="550"
            title="파일 등록/삭제">
      <DxScrollView direction="vertical">
        <form id="form"
              :ref="formRefName"
              method="post"
              enctype="multipart/form-data">
         
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">건설현장 코드:</div>
              <DxTextBox
                :value="constructionSiteCode"
                name="constructionSiteCode"
                disabled="true"
                class="dx-field-value"
              />
            </div>
            <div class="dx-field">
              <div class="dx-field-label">건설현장 명:</div>
              <DxTextBox
                name="constructionSiteName"
                :value="constructionSiteName"
                disabled="true"
                class="dx-field-value"
              />
            </div>
          </div>

          <div class="fileuploader-container">
            <div class="dx-fieldset">
              <div class="dx-field">
                <DxSelectBox
                  :ref="fileTypeRefName"
                  :search-enabled="false"
                  placeholder="파일 종류 선택"
                  value-expr="ID"
                  display-expr="Name"
                  width="350"
                  :data-source="[{ID: 'FILE_0001', Name: '계약서'},
                                 {ID: 'FILE_0002', Name: '계약 내역서'},
                                 {ID: 'FILE_0003', Name: '계약 검토 보고서'},
                                 {ID: 'FILE_0004', Name: '인수 인계서'},
                                 {ID: 'FILE_0005', Name: '담당자 연락처'},
                                 {ID: 'FILE_0006', Name: '지급 보증서'},
                                 {ID: 'FILE_0007', Name: '도면'}]"/>
              </div> 
              <div class="dx-field">
                <DxFileUploader
                  :ref="fileRefName"
                  name="file"
                  select-button-text="파일 선택"
                  upload-mode="useForm"/>
              </div>
            </div>
          </div>
          <div class='buttonContainer'>
            <DxButton
              id="btnSave"
              class="button"
              text="등록"
              icon="save"
              width="130"
              @click="fileUpload"/>
            <DxButton
              id="btnDelete"
              class="button"
              text="삭제"
              icon="remove"
              width="130"
              @click="fileDelete"/>
          </div>
        </form>
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxLookup,
          DxPatternRule,
          DxMasterDetail,
          DxColumnChooser} from "devextreme-vue/data-grid";
  import DxPopup from "devextreme-vue/popup";
  import {DxTextBox} from "devextreme-vue/text-box";
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import {DxFileUploader} from "devextreme-vue/file-uploader";     
  import DxButton from "devextreme-vue/button";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import axios from 'axios';
  import {statusComplete,
          materialType} from "../../utils/syscodedata";
  import History from "../_common/SIMPLE_HISTORY";
  import {DxSelectBox} from "devextreme-vue/select-box";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxLookup,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxPopup,
      DxTextBox,
      DxFileUploader,
      DxScrollView,
      DxButton,
      DxSelectBox,
      DxColumnChooser
    },
    data() {
      return {
        constructionSiteCode: '',
        constructionSiteName: '',
        dataSource: dsu.getComDSGrid('/api/erp/EMHJ', 'code', '', '건설현장 코드'),
        statusComplete: statusComplete,
        materialType: materialType,
        userList: [],
        gridRefName: 'grid',
        popupUploadFileRefName: 'popupUploadFile',
        formRefName: 'form',
        fileTypeRefName: 'fileType',
        fileRefName: 'file',
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                        {dataField : "code2", caption: "성적서 번호(BIPV)"},
                        {dataField : "code3", caption: "성적서 번호(KZIP)" },
                        {dataField : "code4", caption: "성적서 번호(KZIP2)"},
                        {dataField : "name", caption: "건설현장 명"},
                        {dataField : "address", caption: "대지위치"},
                        {dataField : "landNumber", caption : "지번"},
                        {dataField : "customer", caption: "고객(발주처)" },
                        {dataField : "customerAddress", caption: "고객 주소" },
                        {dataField : "customerTel", caption: "고객 전화번호" },
                        {dataField : "receiptDate", caption : "공사 접수일"},
                        {dataField : "startDate", caption : "공사 시작일"},
                        {dataField : "endDate", caption : "공사 종료일"},
                        {dataField : "cqBipv", caption : "시공량(BIPV)"},
                        {dataField : "cqKzip", caption: "시공량(KZIP)" },
                        {dataField : "cqKzip2", caption : "시공량(KZIP2)"},
                        {dataField : "complete", caption : "상태", lookup: {dataSource : statusComplete, valueExpr: "ID", displayExpr : "Name"}},
                        {dataField : "salesUserId", caption : "영업 담당자"},
                        {dataField : "constructionSiteManager", caption : "현장 소장"},
                        {dataField : "type", caption : "내화 구분"},
                        {dataField : "supervisionCompany", caption : "감리회사"},
                        {dataField : "contractor", caption : "시공업자"},
                        {dataField: "checker", caption: "확인자"},    
                        {dataField : "memo", caption : "Memo"},                                                                        
                        {dataField: "startDateInspPeriod", caption: "검사시기 시작일"},
                        {dataField: "endDateInspPeriod", caption: "검사시기 종료일"},
                        {dataField: "startDateInsp", caption: "검사일자 시작일"},
                        {dataField: "endDateInsp", caption: "검사일자 종료일"},
                        {dataField : "file0001", caption : "계약서", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0002", caption : "계약 내역서", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0003", caption : "계약 검토 보고서", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0004", caption : "인수 인계서", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0005", caption : "담당자 연락처", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0006", caption : "지급 보증서", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "file0007", caption : "도면", alignment: "center", cellTemplate: "fileCellTemplate"},
                        {dataField : "userId", caption : "사용자"},
                        {dataField : "dml", caption : "Action"}]
      };
    },
    mounted(){
        dsu.getAxiosUserList({dept: '영업'}).then(res => {this.userList = res.data.data});
    },  
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
           {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'upload',
              text: '파일 등록/삭제',
              stylingMode:"outlined",
              onClick: this.showFileUpload.bind(this)
              }
            },
            {location: 'before',
            widget: 'dxButton',
            options: {
              icon: 'download',
              text: '내화구조 품질관리서',
              stylingMode:"outlined",
              onClick: this.downloadInspReport.bind(this)
            }}       
          );
      },
      showFileUpload(){
        const fileType = this.$refs[this.fileTypeRefName].instance;
        const file = this.$refs[this.fileRefName].instance;
        
        fileType.reset();
        file.reset();

        const grid = this.$refs[this.gridRefName].instance; 
        let selectedRows = grid.getSelectedRowsData();

        if(selectedRows.length == 0){
          fn.notifyError('건설현장 선택 후 진행 필요');
          return;
        }
 
        this.constructionSiteCode = selectedRows[0].code;
        this.constructionSiteName = selectedRows[0].name;
        this.$refs[this.popupUploadFileRefName].instance.show();
      },
      onDblClick(e){
        fn.downLoadFile(e);
      },
      async fileUpload(e){
        const grid = this.$refs[this.gridRefName].instance;
        const form = this.$refs[this.formRefName];
        const fileType = this.$refs[this.fileTypeRefName].instance;
        const file = this.$refs[this.fileRefName].instance;
        
        var params = {};
    
        params.fileType = fileType.option('value');
    
        if(!params.fileType){
          fn.notifyError('파일 종류 미 선택');
          return;
        }
            
        var fileObj = file.option('value');
    
        if(fileObj.length  == 0){
          fn.notifyError('파일 미 선택');
          return;
        }
        
        if (fileObj[0].size > 104857600){
          fn.notifyError('최대용량 초과(100mb)');
          return;
        }

        params.constructionSiteCode = this.constructionSiteCode;
        e.component.option('disabled', true);
        await dsu.getAxiosOBJPostMultiPart('/api/erp/EMHJ/uploadFile', params, form).then(res=>{
            e.component.option('disabled', false);
            let response = res.data;
            if(response.result == 'SUCCESS'){
              grid.refresh();
              fn.alert(response.msg);
            }else{
              fn.alert(response.msg);
            }
        });
      },
      async fileDelete(){
        const fileType = this.$refs[this.fileTypeRefName].instance;
        
        var params = {};
    
        params.fileType = fileType.option('value');
    
        if(!params.fileType){
          fn.notifyError('파일 종류 미 선택');
          return;
        }

        if(await fn.confirm('정말 삭제 하시겠습니까?') == false){
          return;
        } 
            
        params.constructionSiteCode = this.constructionSiteCode;
        
        await dsu.getAxiosOBJPost('/api/erp/EMHJ/deleteFile', params).then(res=>{
            let response = res.data;
            const grid = this.$refs[this.gridRefName].instance;
            if(response.result == 'SUCCESS'){
              grid.refresh();
              fn.alert(response.msg);
            }else{
              fn.alert(response.msg);
            }
        });
      },
      async downloadInspReport(e) {
        const grid = this.$refs[this.gridRefName].instance;
        let selectedRows = grid.getSelectedRowsData();
        if(selectedRows.length == 0){
          fn.notifyError('건설현장 선택 후 진행 필요');
          return;
        }

        const constructionSiteInfo = selectedRows[0];
        var params = { constructionSiteCode: constructionSiteInfo.code };
        dsu.setDefaultParams(params);
        e.component.option('disabled', true);
        try{
            const response = await axios({
                url: '/api/erp/EMHJ/downloadInspResult',
                method: 'GET',
                params: params,
                headers: dsu.getHeaders(),
                responseType: 'blob',
            });
            e.component.option('disabled', false);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            let fileName = constructionSiteInfo.name + '_내화구조_품질관리서.zip';
            e.component.option('disabled', false);
            const link = document.createElement('a');
            link.href = url
            link.download = fileName;
            link.click();

        }catch(e){
          fn.notifyError("내화구조_품질관리서 생서 오류 : " + e);
        }
  




        
      }
    }
   
  };
</script>

<style scoped>
  #Container {
    height: calc( 100vh - 50px );
    width: 100%;}
  
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;}

  .dx-field-label{
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: black;
  }

  .dx-field-value{
    font-size: 14px;
    font-weight: 400;
    color: black;
  }
  
  #form {
      max-width: 600px;
      margin: auto;
  }

  .fileuploader-container{
      border: 1px solid #d3d3d3;
      text-align: center;
      height: 240px;
  }
  
  .buttonContainer{
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #btnSave{
    float: left;
  }

  #btnDelete{
    float: right;
  }

</style>